import { storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import { Button } from "~components/ui";
import { IconButton } from "~components/ui/icon-button";
import { SeedWave } from "~components/ui/seed-wave";
import {
  type PageContext,
  getImageDimensions,
  getLinkProps,
} from "~lib/storyblok";
import { cn, useIsReducedMotion } from "~utils";
import { RichText } from "../rich-text";
import type { HeroSectionBlok } from "./types";

type HeroSectionProps = {
  blok: HeroSectionBlok;
} & PageContext;

export function HeroSection({ blok, ...context }: HeroSectionProps) {
  const [titleFirstPart, ...titleRemaining] = blok.title.split("\n");
  const titleSecondPart = titleRemaining.join("\n");
  const { height, width } = getImageDimensions(blok.imageShowcase) || {};
  const isReducedMotion = useIsReducedMotion();
  const ctaVariant = blok.ctaVariant || "primary";

  const jumpToSection = (id: string | undefined) => {
    if (!id?.length) return;

    const component = document.getElementById(id);
    if (!component) return;

    const firstText = component.querySelector("h1,h2,h3,h4,h5,h6,span,p");
    const elToScrollTo = firstText || component;

    elToScrollTo.scrollIntoView({
      behavior: isReducedMotion ? "instant" : "smooth",
    });
  };

  return (
    <section
      id={blok.sectionId}
      data-section-type="hero"
      className={cn(
        blok.theme,
        "[--second-part-max-w:50rem]",
        "section relative min-h-screen w-full place-content-center overflow-hidden text-content-text",
        !blok.backgroundImageDesktop?.filename && "bg-surface/200",
        blok.textVariant === "white" && "text-others-white",
        blok.textVariant === "black" && "text-others-black",
      )}
      {...storyblokEditable(blok)}
    >
      {blok.pattern !== false && (
        <SeedWave
          className="section-item-full absolute top-[0] z-[2] h-full w-full bg-decor/100"
          seedPatternColor={blok.seedPatternColor}
        />
      )}

      <div
        className={cn(
          "z-10 m-auto flex w-full max-w-[58em] flex-col place-items-center gap-sm",
          blok.centered ? "text-center" : "text-left",
        )}
      >
        {blok.topLogos && (
          <div className="flex flex-wrap gap-sm">
            {blok.topLogos.map(logo => (
              <Image
                key={logo.id}
                sizes="(max-width: 1280px) 100px, 120px"
                {...getImageDimensions(logo)}
                src={logo.filename ?? ""}
                alt={logo.alt ?? ""}
                className="max-w-[100px] lg:max-w-[120px]"
              />
            ))}
          </div>
        )}

        <h1
          className={cn(
            "flex w-full flex-col text-pretty text-content-heading",
            blok.centered ? "t-strong-5xl" : "t-strong-6xl whitespace-pre-line",
            blok.textVariant === "white" && "text-others-white",
            blok.textVariant === "black" && "text-others-black",
            blok.headingStyles,
            blok.titleFontFamily,
          )}
        >
          {blok.centered ? (
            blok.title
          ) : (
            <>
              <span>{titleFirstPart}</span>

              {titleSecondPart && (
                <span className="w-full max-w-[var(--second-part-max-w)] sm:ml-auto">
                  {titleSecondPart}
                </span>
              )}
            </>
          )}
        </h1>

        <div
          className={cn(
            !blok.centered &&
              "w-full max-w-[var(--second-part-max-w)] self-start sm:self-end",
          )}
        >
          <RichText
            data={blok.text}
            classNames={{
              paragraph: cn(
                "t-strong-lg text-pretty text-content-text",
                blok.textVariant === "white" && "text-others-white",
                blok.textVariant === "black" && "text-others-black",
                blok.centered ? "max-w-[55ch]" : "max-w-[36ch]",
              ),
            }}
            {...context}
          />
        </div>

        {blok.ctaSection && (
          <div className="flex justify-center">
            <IconButton
              animated="down"
              name="ArrowDown"
              onClick={() => jumpToSection(blok.ctaSection)}
            />
          </div>
        )}

        {blok.ctaLinkLabel && blok.ctaLink && (
          <Button
            {...getLinkProps(blok.ctaLink, context)}
            variant={ctaVariant}
            arrow
          >
            {blok.ctaLinkLabel}
          </Button>
        )}
      </div>

      {blok.imageShowcase?.filename && (
        <>
          {/* Add extra padding to the section for the image */}
          <div
            className="-mt-[40%] sm:-mt-[15%] w-[100%] sm:w-[80%]"
            style={{ aspectRatio: width && height && `${width / height}` }}
          />
          <Image
            sizes="(max-width: 1280px) 100vw, 70vw"
            height={height}
            width={width}
            src={blok.imageShowcase.filename}
            alt={blok.imageShowcase.alt}
            className="absolute bottom-[0] z-10 sm:left-[10%] sm:w-[80%]"
          />
        </>
      )}

      {blok.backgroundImageDesktop?.filename && (
        <>
          <div className="h-3xl" />
          <Image
            sizes="100vw"
            src={blok.backgroundImageDesktop.filename}
            alt={blok.backgroundImageDesktop.alt}
            {...getImageDimensions(blok.backgroundImageDesktop)}
            className="section-item-full absolute z-0 h-full object-cover"
            priority
          />
        </>
      )}

      {blok.backgroundImageMobile?.filename && (
        <Image
          sizes="100vw"
          src={blok.backgroundImageMobile.filename}
          alt={blok.backgroundImageMobile.alt}
          {...getImageDimensions(blok.backgroundImageMobile)}
          className="section-item-full absolute z-[1] h-full object-cover sm:hidden"
          priority
        />
      )}
    </section>
  );
}
