import { Button } from "~components/ui";
import { type PageContext, getLinkProps } from "~lib/storyblok";
import { cn } from "~utils";
import type { FeaturesSectionBlok } from "../types";

interface FeatureSectionCTAProps extends PageContext {
  blok: FeaturesSectionBlok;
}

function FeatureSectionCTA({
  blok,
  ...context
}: Readonly<FeatureSectionCTAProps>) {
  const alignButton = blok.textAlign === "text-center" ? "mx-auto" : "";
  const ctaVariant = () => {
    if (blok.ctaVariant) {
      return blok.ctaVariant;
    }

    return blok.variant === "simple" ? "primary" : "secondary";
  };
  return blok.ctaLink && blok.ctaText ? (
    <Button
      {...getLinkProps(blok.ctaLink, context)}
      variant={ctaVariant()}
      editorial
      className={cn("mt-sm place-self-start", alignButton)}
    >
      {blok.ctaText}
    </Button>
  ) : null;
}

export { FeatureSectionCTA };
