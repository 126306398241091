"use client";

import { type SbBlokData, storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import { Fragment, useState } from "react";

import { Button, Icon, Rails } from "~components/ui";
import { SeedWave } from "~components/ui/seed-wave";
import {
  type PageContext,
  buildImageLoader,
  getLinkProps,
} from "~lib/storyblok";
import { cn } from "~utils";

import { RichText } from "../rich-text";
import type { TileItemBlok, TileSectionBlok } from "./types";

type TileSectionProps = {
  blok: TileSectionBlok;
} & PageContext;

export function TileSection({ blok, ...context }: TileSectionProps) {
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  return (
    <Rails.Root
      id={blok.sectionId}
      data-section-type="tile"
      className={cn(
        "section relative overflow-hidden",
        blok.theme,
        blok.backgroundVariant === "contrast" && "bg-surface/contrast",
        blok.backgroundVariant === "default" && "bg-surface/100",
        blok.backgroundVariant === "darker" && "bg-surface/200",
      )}
      {...storyblokEditable(blok)}
    >
      <div className="z-10 flex flex-wrap justify-between gap-lg">
        <div className="flex grow flex-col gap-sm">
          {blok.leading && (
            <div className="t-prosi-xl max-w-leading text-balance text-content-heading">
              {blok.leading}
            </div>
          )}

          <h1 className="t-strong-3xl max-w-title/sm text-balance text-content-heading">
            {blok.title}
          </h1>

          <RichText
            className="t-strong-lg max-w-sub text-pretty"
            data={blok.text}
            {...context}
          />

          {blok.ctaLink && blok.ctaText && (
            <Button
              arrow
              variant="secondary"
              className="mr-auto"
              {...getLinkProps(blok.ctaLink, context)}
            >
              {blok.ctaText}
            </Button>
          )}
        </div>

        <div
          className={cn(
            "flex gap-sm place-self-end",
            isPrevDisabled && isNextDisabled && "hidden",
          )}
        >
          <Rails.Control
            action="prev"
            isDisabled={isPrevDisabled}
            onDisabledChanged={setIsPrevDisabled}
          />
          <Rails.Control
            action="next"
            isDisabled={isNextDisabled}
            onDisabledChanged={setIsNextDisabled}
          />
        </div>
      </div>

      <Rails.Viewport
        className={cn(
          "section-item-full z-10 flex gap-sm-md overflow-x-auto md:gap-lg",
          "scrollbar-hidden z-10 snap-x snap-mandatory scroll-px-[var(--section-offset-x)] px-[var(--section-offset-x)]",
        )}
      >
        {blok.items.map((item, index) => (
          <TileSectionItem
            mainBlok={blok}
            key={item._uid}
            blok={item}
            index={index}
            isNumbered={blok.isNumbered}
            isWide={blok.variant === "wide"}
            {...context}
          />
        ))}
      </Rails.Viewport>

      {blok.withSeedsPattern && (
        <SeedWave
          className="section-item-full absolute top-[0] z-0 h-full w-full bg-decor/200"
          seedPatternColor={blok.seedPatternColor}
        />
      )}
    </Rails.Root>
  );
}

type TileItemProps = {
  mainBlok: TileSectionBlok;
  blok: TileItemBlok;
  index: number;
  isNumbered?: boolean;
  isWide?: boolean;
} & PageContext;

function TileSectionItem({
  blok,
  index,
  isNumbered,
  isWide,
  mainBlok,
  ...context
}: TileItemProps) {
  const squareImages = mainBlok.squareImages ?? false;
  const titleFontStyle = mainBlok.fontStyleTitleItems ?? "t-prosi-2xl";
  const descriptionFontStyle =
    mainBlok.fontStyleDescriptionItems ?? "t-default-md";

  return (
    <Rails.Item
      className={cn(
        blok.theme,
        "w-[min(90vw,17em)] shrink-0 snap-center md:w-[min(24em,38vw)] md:snap-start",
        "overflow-hidden rounded-md md:min-h-[23em]",
        blok.variant === "lighter" && "bg-surface/100",
        blok.variant === "default" && "bg-surface/200",
        blok.variant === "darker" && "bg-surface/300",
        blok.variant === "accent" && "bg-surface/accent",
        isWide && "lg:w-[calc(50%-theme(spacing.lg)/2)]",
      )}
      {...storyblokEditable(blok as SbBlokData)}
    >
      {blok.image?.filename &&
        (squareImages ? (
          <div className="aspect-square overflow-hidden relative rounded-md">
            <Image
              width={500}
              height={500}
              sizes="(max-width: 1048px) 80vw, 40vw"
              alt={blok.image?.alt ?? ""}
              src={blok.image.filename}
              loader={buildImageLoader({
                focus: blok.image.focus,
              })}
              className="w-full scale-y-[1.02] origin-top h-full object-cover"
            />
          </div>
        ) : (
          <Fragment>
            <Image
              width={isWide ? 380 : 307}
              height="180"
              alt={blok.image?.alt ?? ""}
              src={blok.image.filename}
              loader={buildImageLoader({
                aspectRatio: isWide ? 380 / 180 : 307 / 180,
                focus: blok.image.focus,
              })}
              className="w-full md:hidden"
            />
            <Image
              width={isWide ? 560 : 460}
              height="240"
              sizes="(max-width: 1048px) 80vw, 40vw"
              alt={blok.image?.alt ?? ""}
              src={blok.image.filename}
              loader={buildImageLoader({
                aspectRatio: isWide ? 560 / 240 : 460 / 240,
                focus: blok.image.focus,
              })}
              className="w-full max-md:hidden"
            />
          </Fragment>
        ))}

      <div className="grid place-content-start gap-sm p-md text-content-text md:p-lg">
        {blok.icon && !isNumbered && (
          <Icon name={blok.icon} weight="duotone" className="circle-icon" />
        )}

        {isNumbered && (
          <div className="t-strong-md box-content grid w-[1em] place-items-center place-self-start rounded-full border border-content-head p-xs leading-none">
            {index + 1}
          </div>
        )}

        {blok.leading && <span className="t-strong-md">{blok.leading}</span>}

        <h2 className={cn("text-balance", titleFontStyle)}>{blok.title}</h2>

        <RichText
          className={cn("text-pretty", descriptionFontStyle)}
          data={blok.text}
          {...context}
        />

        {blok.ctaLink && blok.ctaText && (
          <a
            {...getLinkProps(blok.ctaLink, context)}
            className="t-strong-sm my-2xs leading-tight underline"
          >
            {blok.ctaText}
          </a>
        )}
      </div>
    </Rails.Item>
  );
}
