import { storyblokEditable } from "@storyblok/react";
import type { PageContext } from "~lib/storyblok";
import { cn } from "~utils";
import type { StatsSectionBlok, StatsSectionItemBlok } from "./types";

interface StatsSectionProps extends PageContext {
  blok: StatsSectionBlok;
}

interface StatsSectionItemProps {
  blok: StatsSectionItemBlok;
}

function StatsSection({ blok }: Readonly<StatsSectionProps>) {
  return (
    <div
      className="flex flex-col gap-md px-sm pt-xs lg:px-2xl lg:pt-3xl"
      {...storyblokEditable(blok)}
    >
      <div className={cn(blok.leadingFontStyle, "text-center")}>
        {blok.leading}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-sm divide-y md:divide-y-0 md:divide-x divide-[#969696]">
        {blok.items?.map(item => (
          <StatsSectionItem key={blok._uid} blok={item} />
        ))}
      </div>
    </div>
  );
}

function StatsSectionItem({ blok }: Readonly<StatsSectionItemProps>) {
  return (
    <div className="text-center" {...storyblokEditable(blok)}>
      <h3 className={cn(blok.primaryTextFontStyle)}>{blok.primaryText}</h3>
      <span className={cn(blok.secondaryTextFontStyle)}>
        {blok.secondaryText}
      </span>
    </div>
  );
}
export { StatsSection, StatsSectionItem };
