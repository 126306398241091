import * as Accordion from "@radix-ui/react-accordion";
import { storyblokEditable } from "@storyblok/react";
import { RichText } from "~components/cms/rich-text";
import { Icon, type IconName } from "~components/ui";
import type { PageContext } from "~lib/storyblok";
import { cn } from "~utils";
import type { FAQSectionItemBlok } from "../types";

type FAQSectionItemProps = {
  blok: FAQSectionItemBlok;
} & PageContext;

function FAQSectionItem({ blok, ...context }: FAQSectionItemProps) {
  const icon: IconName = blok.icon || "Info";

  return (
    <Accordion.Item
      value={blok._uid}
      {...storyblokEditable(blok)}
      className="grid grid-cols-[max-content_1fr_max-content] gap-x-sm-md"
    >
      <Accordion.Header className="col-[1/4] grid">
        <Accordion.Trigger
          className={cn(
            "group flex w-full items-center gap-sm-md",
            "t-strong-lg border-border/300 border-b py-xs-sm pl-[0] text-left text-content-heading leading-snug",
            "data-[state=open]:border-secondaryUI-focus data-[state=open]:border-b-2",
            "data-[state=open]:text-secondaryUI-focus",
          )}
        >
          <Icon
            weight="duotone"
            name={icon}
            className="circle-icon max-sm:hidden"
          />

          <span className="max-sm:col-[1/3]">{blok.title}</span>

          <Icon
            name="CaretDown"
            className="group-data-[state=open]:rotate-180 ml-auto"
          />
        </Accordion.Trigger>
      </Accordion.Header>

      <Accordion.Content
        className={cn(
          "t-default-md flex flex-col gap-sm md:pl-sm-md leading-normal data-[state=open]:py-md-lg",
          "col-[1/4] sm:col-[2/4]",
          // offset by the width of a circle icon
          "sm:ml-[calc(32px_+_2_*_theme(spacing.xs))]",
        )}
      >
        <RichText data={blok.text} {...context} />
      </Accordion.Content>
    </Accordion.Item>
  );
}

export { FAQSectionItem };
