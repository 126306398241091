import type { ComponentProps } from "react";

function Leaf({
  fill = "currentColor",
  ...props
}: Readonly<ComponentProps<"svg">>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 449 448"
      {...props}
    >
      <title>leaf icon</title>
      <path
        fill={fill}
        d="M449 0C334.486 1.591 220.239 45.622 132.889 132.888S1.332 333.944 0 448c114.514-1.591 228.761-45.622 316.111-132.888S447.668 114.056 449 0Z"
      />
    </svg>
  );
}

export { Leaf };
