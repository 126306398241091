import * as Accordion from "@radix-ui/react-accordion";
import { storyblokEditable } from "@storyblok/react/rsc";

import { SeedWave } from "~components/ui/seed-wave";
import type { PageContext } from "~lib/storyblok";
import { cn } from "~utils";

import { RichText } from "../rich-text";
import { FAQSectionItem } from "./components/faq-section-item";
import type { FAQSectionBlok } from "./types";

type FAQSectionProps = {
  blok: FAQSectionBlok;
} & PageContext;

export function FAQSection({ blok, ...context }: FAQSectionProps) {
  const hasSeedPattern = blok.seedPattern ?? true;
  const backgroundColor = blok.customBackgroundColor
    ? { backgroundColor: blok.customBackgroundColor }
    : {};

  return (
    <section
      id={blok.sectionId}
      className={cn(
        blok.theme,
        "[--second-part-max-w:50rem]",
        "section relative min-h-screen w-full place-content-center overflow-hidden bg-surface/200 text-content-text",
        blok.backgroundVariant === "darker" && "bg-surface/300",
        blok.backgroundVariant === "accent" && "bg-surface/accent",
        blok.backgroundVariant === "lighter" && "bg-surface/100",
      )}
      {...storyblokEditable(blok)}
      style={{ ...backgroundColor }}
    >
      {hasSeedPattern && (
        <SeedWave
          className="section-item-full absolute top-[0] z-[2] h-full w-full bg-decor/100"
          seedPatternColor={blok.seedPatternColor}
        />
      )}

      <div
        className={cn(
          "z-10 m-auto flex w-full max-w-[58em] flex-col gap-md rounded-sm bg-surface/contrast",
          "px-sm md:px-lg-xl py-md md:py-xl-2xl",
        )}
      >
        <div className="flex flex-col gap-sm">
          <h1 className="t-strong-3xl max-w-title/sm text-pretty text-content-heading">
            {blok.title}
          </h1>

          {blok.text && (
            <RichText
              data={blok.text}
              classNames={{
                paragraph: cn(
                  "t-default-md max-w-text text-pretty text-content-text",
                ),
              }}
              {...context}
            />
          )}
        </div>

        <Accordion.Root type="multiple">
          {blok.items.map(item => (
            <FAQSectionItem key={item._uid} blok={item} {...context} />
          ))}
        </Accordion.Root>
      </div>
    </section>
  );
}
