import { type SbBlokData, storyblokEditable } from "@storyblok/react";
import type { CSSProperties } from "react";
import { Icon } from "~components/ui";
import type { PageContext } from "~lib/storyblok";
import { cn } from "~utils";
import { RichText } from "../../rich-text";
import type { FeatureSectionItem, FeaturesSectionBlok } from "../types";

interface FeatureSectionItemsProps extends PageContext {
  blok: FeaturesSectionBlok;
}

interface FeatureItemProps extends PageContext {
  blok: FeaturesSectionBlok;
  item: FeatureSectionItem;
}

function FeatureSectionItems({
  blok,
  ...context
}: Readonly<FeatureSectionItemsProps>) {
  const gridColumnsWithGap =
    blok.itemsGap === "gap-xs" || blok.itemsGap === "gap-sm"
      ? "md:grid-cols-[repeat(auto-fill,minmax(16.5rem,1fr))]"
      : "md:grid-cols-[repeat(auto-fit,17.5rem)]";

  return (
    <ul
      className={cn(
        "grid rounded",
        blok.variant === "simple" ? gridColumnsWithGap : "xl:grid-cols-3",
        blok.itemsGap,
      )}
    >
      {blok.items.map(item => (
        <FeatureItem key={item._uid} blok={blok} item={item} {...context} />
      ))}
    </ul>
  );
}

function FeatureItem({ item, blok, ...context }: Readonly<FeatureItemProps>) {
  const iconWeight = item?.iconWeight || "duotone";

  const backgroundColor: CSSProperties = item.backgroundColor
    ? { backgroundColor: item.backgroundColor }
    : {};
  return (
    <li
      className={cn(
        "flex gap-sm p-sm",
        blok.variant === "simple"
          ? "t-strong-lg md:flex-col max-md:items-center"
          : "flex max-xl:items-center",
        item.borderRadius,
      )}
      style={{
        ...backgroundColor,
      }}
      {...storyblokEditable(item as SbBlokData)}
    >
      <Icon name={item.icon} className="circle-icon" weight={iconWeight} />
      <div className="flex flex-col">
        <RichText
          data={item.text}
          {...context}
          className="max-w-sub text-pretty"
        />

        {item.secondaryText && (
          <RichText
            data={item.secondaryText}
            {...context}
            className="max-w-sub text-pretty text-default-md"
          />
        )}
      </div>
    </li>
  );
}

export { FeatureSectionItems };
